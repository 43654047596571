export default function categoryTabs() {
    $('.category-tabs .links a').on('click', function (e) {
        e.preventDefault();
        $('.category-tabs .links a').removeClass('active');
        $(this).addClass('active');
        var tag = $(this).attr('href');
        $('.category-tabs .item').removeClass('active');
        $('[data-link="' + tag + '"]').addClass('active');
    });
}
