import Swiper, { Navigation, Pagination, Lazy } from 'swiper';
Swiper.use([Navigation, Pagination, Lazy]);

export default function setProductSlider() {
    var sliderSectionHome = new Swiper('#swiper-section', {
        loop: false,
        slidesPerView: 1.7,
        spaceBetween: 15,
        watchOverflow: true,
        pagination: {
            el: '.swiper-pagination-section',
            type: 'bullets',
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + '"></span>';
            },
        },
        breakpoints: {
            640: {
                slidesPerView: 1.7,
                spaceBetween: 15,
            },
            768: {
                slidesPerView: 1.7,
                spaceBetween: 30,
            },
            1024: {
                slidesPerView: 3.3,
                spaceBetween: 40,
            },
        },
    });
}
