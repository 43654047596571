import setFullbanner from './home/fullbanner';
import setProductSlider from './home/product_slider';
import categoryTabs from './home/category_tabs';
import setInstagram from './home/instagram';
//addImports

const Home = {
  init: function () {
    setFullbanner()
setProductSlider()
categoryTabs()
setInstagram()
//calls
  },
};

window.addEventListener('DOMContentLoaded', () => {
  Home.init()
})
